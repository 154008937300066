<template>
  <div class="user-tab-security">
    <file-table
      :loading="false"
      :files="localFiles"
      groups-disabled
      :handle-document-generation="false"
      disable-upload
      disable-delete
      disable-update
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useState } from 'vuex-composition-helpers'

import _ from 'lodash'
import FileTable from '@/components/files-table/FileTable.vue'

export default {
  components: {
    FileTable,
  },
  setup() {
    const { talentPoolEntry } = useState('talentPool', ['talentPoolEntry'])

    const localFiles = ref([])

    localFiles.value = _.cloneDeep(talentPoolEntry.value.latestCv)

    return {
      localFiles,
    }
  },
}
</script>
<style scoped lang="scss"></style>
