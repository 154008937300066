<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title
          v-touch="{
            left: () =>
              goToNext(talentPoolEntry.talentPoolEntryIds, talentPoolEntry.id, loading, {
                name: 'apps-ats-talent-pool-entry-view',
                param: 'id',
              }),
            right: () =>
              goToPrevious(talentPoolEntry.talentPoolEntryIds, talentPoolEntry.id, loading, {
                name: 'apps-ats-talent-pool-entry-view',
                param: 'id',
              }),
          }"
          class="justify-center flex-column"
        >
          <div class="d-flex justify-space-around align-center w-full">
            <v-btn
              icon
              color="primary"
              :disabled="!canGoToPrevious(talentPoolEntry.talentPoolEntryIds, talentPoolEntry.id) || loading"
              @click="
                goToPrevious(talentPoolEntry.talentPoolEntryIds, talentPoolEntry.id, loading, {
                  name: 'apps-ats-talent-pool-entry-view',
                  param: 'id',
                })
              "
              ><v-icon>{{ icons.mdiChevronLeft }}</v-icon></v-btn
            >
            <v-avatar
              :color="talentPoolEntry.avatar ? '' : 'primary'"
              :class="talentPoolEntry.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="120"
              rounded
              class="mb-4"
            >
              <v-img
                v-if="talentPoolEntry.avatar"
                :src="require(`@/assets/images/avatars/${talentPoolEntry.avatar}`)"
              />
              <span v-else class="font-weight-semibold text-5xl">{{
                avatarText(`${talentPoolEntry.firstName} ${talentPoolEntry.lastName}`)
              }}</span>
            </v-avatar>
            <v-btn
              icon
              color="primary"
              :disabled="!canGoToNext(talentPoolEntry.talentPoolEntryIds, talentPoolEntry.id) || loading"
              @click="
                goToNext(talentPoolEntry.talentPoolEntryIds, talentPoolEntry.id, loading, {
                  name: 'apps-ats-talent-pool-entry-view',
                  param: 'id',
                })
              "
            >
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </div>

          <span class="mb-2">{{ talentPoolEntry.firstName }} {{ talentPoolEntry.lastName }}</span>
          <v-chip
            label
            small
            :color="resolveGDPRStatusColor(talentPoolEntry.gdprStatus).color"
            :class="`v-chip-light-bg ${
              resolveGDPRStatusColor(talentPoolEntry.gdprStatus).color
            }--text font-weight-semibold text-capitalize ma-1 mb-2`"
            >{{ $t(resolveGDPRStatusColor(talentPoolEntry.gdprStatus).text) }}</v-chip
          >
          <span class="d-flex justify-center align-center flex-wrap">
            <v-chip
              v-for="(tag, index) in talentPoolEntry.tags"
              :key="`Talent-Pool-Entry--Tag-${index}`"
              label
              small
              color="info"
              outlined
              class="font-weight-semibold ma-1"
              v-text="tag.tag"
            />
          </span>
        </v-card-title>

        <v-card-text>
          <h2 v-if="isDeleteRequested" v-t="'anonymizationRequest'" class="text-xl font-weight-semibold mb-2" />
          <v-divider v-if="isDeleteRequested" />
          <v-list v-if="isDeleteRequested">
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('anonymizationRequestRemaining') }}:</span>
              <span class="text--secondary"
                >{{ talentPoolEntry.consent.anonymizationRequestRemaining }} {{ $t('days') }}</span
              >
            </v-list-item>
          </v-list>
          <v-row v-if="isDeleteRequested" justify="center" class="my-2">
            <v-col cols="auto">
              <v-btn small color="error" @click="talentPoolDeclineAnonymizationRequestHandler">
                <v-icon class="me-2" size="16">{{ icons.mdiClose }}</v-icon>
                <span v-t="'declineRequest'" />
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn small color="success" @click="talentPoolAcceptAnonymizationRequestHandler">
                <v-icon class="me-2" size="16">{{ icons.mdiCheck }}</v-icon>
                <span v-t="'acceptRequest'" />
              </v-btn>
            </v-col>
          </v-row>

          <h2 v-t="'details'" class="text-xl font-weight-semibold mb-2" :class="isDeleteRequested ? ['mt-4'] : []" />
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('firstName', { postfix: '' }) }}:</span>
              <span class="text--secondary">{{ talentPoolEntry.firstName }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('lastName', { postfix: '' }) }}:</span>
              <span class="text--secondary">{{ talentPoolEntry.lastName }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('gdprStatus') }}:</span>
              <v-chip
                label
                small
                :color="resolveGDPRStatusColor(talentPoolEntry.gdprStatus).color"
                :class="`v-chip-light-bg ${
                  resolveGDPRStatusColor(talentPoolEntry.gdprStatus).color
                }--text font-weight-semibold text-capitalize ma-1 mb-2`"
              >
                {{ $t(resolveGDPRStatusColor(talentPoolEntry.gdprStatus).text) }}
              </v-chip>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('validUntil') }}:</span>
              <span :class="`${resolveValidUntilDateColor(talentPoolEntry.consent.validUntil)}--text`">{{
                formatDateToMonthShort(talentPoolEntry.consent.validUntil)
              }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('email', { postfix: '' }) }}:</span>
              <span class="text--secondary">{{ emptyValueFormatter(talentPoolEntry.email) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('phoneNumber') }}:</span>
              <span class="text--secondary">{{ emptyValueFormatter(talentPoolEntry.phone) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('invitedBy') }}:</span>
              <span class="text--secondary">{{ emptyValueFormatter(talentPoolEntry.invitedBy) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('createdAt') }}:</span>
              <span class="text--secondary">{{ formatDateToMonthShort(talentPoolEntry.createdAt) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('updatedAt') }}:</span>
              <span class="text--secondary">{{ formatDateToMonthShort(talentPoolEntry.updatedAt) }}</span>
            </v-list-item>
          </v-list>

          <v-row justify="center" class="my-2">
            <v-col v-if="talentPoolEntry.canInviteToJob" cols="auto">
              <v-btn small color="primary" outlined @click="isInviteToJobDialogOpen = !isInviteToJobDialogOpen">
                <v-icon class="me-2" size="16">{{ icons.mdiBriefcaseUploadOutline }}</v-icon>
                <span v-t="'inviteToJob'" />
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn small color="primary" @click="isBioDialogOpen = !isBioDialogOpen">
                <v-icon class="me-2" size="16">{{ icons.mdiPencilOutline }}</v-icon>
                <span v-t="'edit'" />
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" class="my-2">
            <v-col v-if="talentPoolEntry.canSetForAnonymizationStatus" cols="auto">
              <v-btn small color="info" outlined @click="talentPoolEntrySetForAnonymizationHandler">
                <span v-t="'setForAnonymization'" />
              </v-btn>
            </v-col>
            <v-col v-if="talentPoolEntry.canAutoAnonymizationDisabledStatus" cols="auto">
              <v-btn small color="warning" outlined @click="talentPoolEntryAutoAnonymizationDisabledHandler">
                <span v-t="'autoAnonymizationDisabled'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-dialog
        v-if="talentPoolEntry.canInviteToJob"
        v-model="isInviteToJobDialogOpen"
        max-width="850px"
        persistent
        overlay-color="#7f7f7f"
        overlay-opacity="0.2"
      >
        <v-card class="pa-sm-10 pa-3">
          <v-card-title v-t="'inviteToJob'" class="justify-center text-h5" />
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex align-center justify-center flex-column">
                <div v-if="!selectedEmail" v-t="'confirmationModalDefaultEmail'" />
                <div v-else v-t="'confirmationModalDefaultEmailConditions'" />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="job"
                  :label="$t('selectJob')"
                  :placeholder="$t('selectJob')"
                  :items="jobChoices"
                  item-value="id"
                  item-text="title"
                  outlined
                  dense
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="selectedEmail"
                  :label="$t('selectEmail')"
                  :placeholder="$t('selectEmail')"
                  :items="emailTemplatesChoices"
                  outlined
                  dense
                  hide-details="auto"
                  @change="setEmailTemplateData"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="7">
                <v-text-field
                  v-model="cc"
                  :label="$t('cc')"
                  :placeholder="$t('cc')"
                  outlined
                  dense
                  maxlength="100"
                  hide-details="auto"
                  :hint="$t('separateByComma')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-switch v-model="sendCopyToRecruiter" class="mt-0" inset color="primary">
                  <template v-slot:label> {{ $t('sendMeCopy') }} </template>
                </v-switch>
              </v-col>
            </v-row>
            <v-form v-if="selectedEmail">
              <v-row>
                <v-col cols="12" sm="7">
                  <v-text-field
                    v-model="selectedEmailTemplateSubject"
                    :label="$t('subject')"
                    :placeholder="$t('subject')"
                    outlined
                    dense
                    maxlength="100"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-select
                    v-model="selectedEmailTemplateLang"
                    outlined
                    dense
                    :items="[
                      {
                        text: $t('pl'),
                        value: 'pl',
                      },
                      {
                        text: $t('eng'),
                        value: 'eng',
                      },
                    ]"
                    :label="$t('language')"
                    :placeholder="$t('language')"
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <summer-note
                    :config="{ height: 300 }"
                    hide-link-and-image-button
                    @ready="readySummerNote"
                  ></summer-note>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12" class="d-flex justify-center mt-3">
                <v-btn v-t="'discard'" outlined class="me-3" @click="closeInviteToJobDialog" />
                <v-btn color="primary" :loading="sendingInvitationToJob" :disabled="!job" @click="sendInvitation">
                  {{ $t('invite') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <talent-pool-entry-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :talent-pool-entry-data.sync="talentPoolEntry"
      ></talent-pool-entry-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiBriefcaseOutline,
  mdiClose,
  mdiArrowRight,
  mdiCheck,
  mdiBriefcaseUploadOutline,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import { ref, computed, getCurrentInstance, nextTick } from '@vue/composition-api'
import { useState, useActions } from 'vuex-composition-helpers'

import { avatarText, formatDateToMonthShort, emptyValueFormatter } from '@core/utils/filter'
import { invoke, until } from '@vueuse/core'
import SummerNote from '@/plugins/summer-note/SummerNote.vue'
import TalentPoolEntryBioEdit from './TalentPoolEntryBioEdit.vue'
import { EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_SUMMERNOTE } from '@/plugins/summer-note/variables'
import useTalentPoolList from '@/views/apps/ats/talent-pool/talent-pool-list/useTalentPoolList'
import navigationUtils from '@/utils/navigationUtils'

export default {
  components: {
    TalentPoolEntryBioEdit,
    SummerNote,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const { resolveGDPRStatusColor, resolveValidUntilDateColor } = useTalentPoolList()

    const isBioDialogOpen = ref(false)
    const isInviteToJobDialogOpen = ref(false)

    const { getEmailTemplatesChoices, getJobChoices } = useActions('jobs', [
      'getEmailTemplatesChoices',
      'getJobChoices',
    ])
    const {
      talentPoolEntryAutoAnonymizationDisabled,
      talentPoolEntrySetForAnonymization,
      talentPoolAcceptAnonymizationRequest,
      talentPoolDeclineAnonymizationRequest,
      sendInvitationToJob,
    } = useActions('talentPool', [
      'talentPoolEntryAutoAnonymizationDisabled',
      'talentPoolEntrySetForAnonymization',
      'talentPoolAcceptAnonymizationRequest',
      'talentPoolDeclineAnonymizationRequest',
      'sendInvitationToJob',
    ])
    const { talentPoolEntry } = useState('talentPool', ['talentPoolEntry'])

    const { canGoToNext, goToNext, canGoToPrevious, goToPrevious } = navigationUtils()

    const isDeleteRequested = computed(() => {
      return talentPoolEntry.value.gdprStatus === 'anonymization_request'
    })

    const job = ref(null)
    const jobChoices = ref([])

    const emailTemplatesChoices = ref([])
    const selectedEmail = ref(null)
    const selectedEmailTemplateHtml = ref('')
    const selectedEmailTemplateSubject = ref('')
    const selectedEmailTemplateLang = ref('')
    const cc = ref('')
    const sendCopyToRecruiter = ref(false)

    const sendingInvitationToJob = ref(false)

    const vm = getCurrentInstance().proxy

    const editor = ref(null)
    const emailVariablesSummernote = ref([])
    emailVariablesSummernote.value = EMAIL_VARIABLES_INVITE_TO_JOB_FROM_TALENT_POOL_SUMMERNOTE(vm)
    const readySummerNote = (initializedEditor) => {
      editor.value = initializedEditor
      editor.value.summernote('code', selectedEmailTemplateHtml.value)
      editor.value.$on('change', (content) => {
        selectedEmailTemplateHtml.value = content
      })
    }

    const setEmailTemplateData = () => {
      const emailTemplate = emailTemplatesChoices.value.find((et) => et.value === selectedEmail.value)
      selectedEmailTemplateHtml.value = emailTemplate?.bodyHtml
      selectedEmailTemplateSubject.value = emailTemplate?.subject
      selectedEmailTemplateLang.value = emailTemplate?.lang
      editor.value?.summernote('code', selectedEmailTemplateHtml.value)
    }

    const resetEmailTemplateData = () => {
      selectedEmail.value = null
      selectedEmailTemplateHtml.value = ''
      selectedEmailTemplateSubject.value = ''
      selectedEmailTemplateLang.value = ''
      cc.value = ''
      sendCopyToRecruiter.value = false
      editor.value?.summernote('code', selectedEmailTemplateHtml.value)
    }

    const closeInviteToJobDialog = () => {
      isInviteToJobDialogOpen.value = false
      job.value = null
      sendingInvitationToJob.value = false
      resetEmailTemplateData()
    }

    invoke(async () => {
      await until(computed(() => isInviteToJobDialogOpen.value)).toBe(true)
      nextTick(async () => {
        jobChoices.value = await getJobChoices({
          candidate: talentPoolEntry.value.candidateId,
          stage: 'ongoing',
        })
        emailTemplatesChoices.value = await getEmailTemplatesChoices({ actionType: 'invite_to_job_tp' })
      })
    })

    const talentPoolEntrySetForAnonymizationHandler = async () => {
      await talentPoolEntrySetForAnonymization(talentPoolEntry.value.id)
      emit('refetchTalentPoolEntryDetails')
    }

    const talentPoolEntryAutoAnonymizationDisabledHandler = async () => {
      await talentPoolEntryAutoAnonymizationDisabled(talentPoolEntry.value.id)
      emit('refetchTalentPoolEntryDetails')
    }

    const talentPoolAcceptAnonymizationRequestHandler = async () => {
      await talentPoolAcceptAnonymizationRequest(talentPoolEntry.value.id)
      emit('refetchTalentPoolEntryDetails')
    }

    const talentPoolDeclineAnonymizationRequestHandler = async () => {
      await talentPoolDeclineAnonymizationRequest(talentPoolEntry.value.id)
      emit('refetchTalentPoolEntryDetails')
    }

    const sendInvitation = async () => {
      sendingInvitationToJob.value = true
      await sendInvitationToJob({
        talentPoolId: talentPoolEntry.value.id,
        payload: {
          inviteJob: job.value,
          bodyHtml: selectedEmailTemplateHtml.value,
          subject: selectedEmailTemplateSubject.value,
          lang: selectedEmailTemplateLang.value,
          cc: cc.value,
          sendCopyToRecruiter: sendCopyToRecruiter.value,
        },
      })
      closeInviteToJobDialog()
    }

    return {
      emit,

      formatDateToMonthShort,
      emptyValueFormatter,
      resolveValidUntilDateColor,
      resolveGDPRStatusColor,
      avatarText,
      talentPoolEntrySetForAnonymizationHandler,
      talentPoolEntryAutoAnonymizationDisabledHandler,
      talentPoolAcceptAnonymizationRequestHandler,
      talentPoolDeclineAnonymizationRequestHandler,
      canGoToNext,
      goToNext,
      canGoToPrevious,
      goToPrevious,

      isDeleteRequested,

      job,
      jobChoices,
      talentPoolEntry,
      isBioDialogOpen,
      isInviteToJobDialogOpen,
      sendingInvitationToJob,

      emailTemplatesChoices,
      selectedEmail,
      selectedEmailTemplateHtml,
      selectedEmailTemplateSubject,
      selectedEmailTemplateLang,
      cc,
      sendCopyToRecruiter,

      setEmailTemplateData,
      closeInviteToJobDialog,
      sendInvitation,

      editor,
      emailVariablesSummernote,
      readySummerNote,

      icons: {
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiCloseCircleOutline,
        mdiBriefcaseOutline,
        mdiClose,
        mdiArrowRight,
        mdiCheck,
        mdiBriefcaseUploadOutline,
        mdiChevronLeft,
        mdiChevronRight,
      },
    }
  },
}
</script>
