<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            talentPoolEntryName: `${talentPoolEntry.firstName} ${talentPoolEntry.lastName}`,
          }"
        />
        <talent-pool-entry-bio-panel
          v-if="talentPoolEntry.id"
          :loading="loading"
          @refetchTalentPoolEntryDetails="fetchTalentPoolEntryDetails"
        />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="selectedTag" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(tab.title) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-if="!loading" id="user-tabs-content" v-model="selectedTag" class="mt-5 pa-3" touchless>
          <v-tab-item>
            <talent-pool-entry-tab-files v-if="talentPoolEntry.id" />
          </v-tab-item>
          <v-tab-item>
            <talent-pool-entry-tab-job-history />
          </v-tab-item>
          <v-tab-item>
            <talent-pool-entry-tab-custom-fields />
          </v-tab-item>
          <v-tab-item>
            <talent-pool-entry-tab-consents />
          </v-tab-item>
          <v-tab-item>
            <talent-pool-entry-tab-internal-notes @refetch-data="fetchTalentPoolEntryDetails" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from '@vue/composition-api'
import { useActions, useMutations, useState } from 'vuex-composition-helpers'
import { mdiCalendarCheckOutline, mdiFileOutline, mdiCommentOutline, mdiBriefcaseOutline, mdiDrag } from '@mdi/js'
import { useRouter } from '@core/utils'
import router from '@/router'

import TalentPoolEntryBioPanel from './talent-pool-entry-bio-panel/TalentPoolEntryBioPanel.vue'
import TalentPoolEntryTabFiles from './talent-pool-entry-tab-files/TalentPoolEntryTabFiles.vue'
import TalentPoolEntryTabJobHistory from './talent-pool-entry-tab-job-history/TalentPoolEntryTabJobHistory.vue'
import TalentPoolEntryTabConsents from './talent-pool-entry-tab-consents/TalentPoolEntryTabConsents.vue'
import TalentPoolEntryTabInternalNotes from './talent-pool-entry-tab-internal-notes/TalentPoolEntryTabInternalNotes.vue'
import TalentPoolEntryTabCustomFields from './talent-pool-entry-tab-custom-fields/TalentPoolEntryTabCustomFields.vue'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'

export default {
  components: {
    BreadCrumbs,
    TalentPoolEntryBioPanel,
    TalentPoolEntryTabFiles,
    TalentPoolEntryTabJobHistory,
    TalentPoolEntryTabConsents,
    TalentPoolEntryTabInternalNotes,
    TalentPoolEntryTabCustomFields,
  },
  setup() {
    const { getTalentPoolEntryDetails } = useActions('talentPool', ['getTalentPoolEntryDetails'])
    const { talentPoolEntry } = useState('talentPool', ['talentPoolEntry'])
    const { SET_TALENT_POOL_ENTRY_DATA } = useMutations('talentPool', ['SET_TALENT_POOL_ENTRY_DATA'])

    const tabs = [
      { icon: mdiFileOutline, title: 'files' },
      { icon: mdiBriefcaseOutline, title: 'jobHistory' },
      { icon: mdiDrag, title: 'customFields' },
      { icon: mdiCalendarCheckOutline, title: 'consent' },
      { icon: mdiCommentOutline, title: 'internalNotes' },
    ]

    const selectedTag = ref(null)
    const loading = ref(false)

    const fetchTalentPoolEntryDetails = async () => {
      loading.value = true
      await getTalentPoolEntryDetails(router.currentRoute.params.id)
      loading.value = false
    }

    const { route } = useRouter()

    watch(
      () => route.value.params.id,
      async () => {
        await fetchTalentPoolEntryDetails()
      },
    )

    onMounted(async () => {
      await fetchTalentPoolEntryDetails()
    })

    onUnmounted(() => {
      SET_TALENT_POOL_ENTRY_DATA({})
    })

    return {
      fetchTalentPoolEntryDetails,

      tabs,
      selectedTag,
      talentPoolEntry,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
