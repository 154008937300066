<template>
  <div class="user-tab-security">
    <v-card class="mb-7">
      <v-card-title v-t="'jobHistory'" />
      <v-data-table
        :headers="tableColumns"
        :show-select="false"
        :items="talentPoolEntry.jobHistory"
        disable-pagination
        hide-default-footer
        fixed-header
        :mobile-breakpoint="0"
      >
        <template #[`header.title`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.stage`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.candidate.step.name`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.candidate.stage`]="{ header }">
          {{ $t(header.text, { postfix: '' }) }}
        </template>
        <template #[`item.stage`]="{ item }">
          <v-chip
            label
            small
            :color="resolveJobStageColor(item.stage).color"
            :class="`v-chip-light-bg ${
              resolveJobStageColor(item.stage).color
            }--text font-weight-semibold text-capitalize`"
            >{{ resolveJobStageColor(item.stage).text }}</v-chip
          >
        </template>
        <template #[`item.title`]="{ item }">
          <router-link
            :to="{ name: 'apps-ats-job-view', params: { id: item.id } }"
            class="font-weight-semibold text-truncate cursor-pointer text-decoration-none ms-3"
          >
            {{ item.title }}
          </router-link>
        </template>
        <template #[`item.candidate.stage`]="{ item }">
          <v-chip
            small
            label
            :color="resolveCandidateStageColor(item.candidate.stage).color"
            :class="`v-chip-light-bg ${
              resolveCandidateStageColor(item.candidate.stage).color
            }--text font-weight-medium text-capitalize`"
          >
            {{ resolveCandidateStageColor(item.candidate.stage).text }}
          </v-chip>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-row justify="center" no-gutters>
            <v-col cols="auto">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    :to="{
                      name: 'apps-ats-candidate-view',
                      params: { jobId: item.id, candidateId: item.candidate.candidate.id },
                    }"
                    v-on="on"
                  >
                    <v-icon size="18">
                      {{ icons.mdiEyeOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span v-t="'view'" />
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { useState } from 'vuex-composition-helpers'
import { mdiEyeOutline } from '@mdi/js'
import useCandidateList from '@/views/apps/ats/jobs/job-view/job-tab-candidates/useCandidateList'
import useJobList from '@/views/apps/ats/jobs/job-list/useJobList'

export default {
  setup() {
    const { resolveCandidateStageColor } = useCandidateList()
    const { resolveJobStageColor } = useJobList()

    const { talentPoolEntry } = useState('talentPool', ['talentPoolEntry'])

    const tableColumns = [
      { text: 'title', value: 'title', class: 'ps-7' },
      { text: 'jobStage', value: 'stage' },
      { text: 'step', value: 'candidate.step.name' },
      { text: 'status', value: 'candidate.stage' },
      {
        text: 'actions',
        value: 'actions',
        align: 'center',
        sortable: false,
        class: 'text-uppercase',
      },
    ]

    return {
      resolveCandidateStageColor,
      resolveJobStageColor,

      talentPoolEntry,
      tableColumns,
      icons: {
        mdiEyeOutline,
      },
    }
  },
}
</script>
<style scoped lang="scss"></style>
