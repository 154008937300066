var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-tab-security"},[_c('v-card',{staticClass:"mb-7"},[_c('v-card-title',{directives:[{name:"t",rawName:"v-t",value:('jobHistory'),expression:"'jobHistory'"}]}),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"show-select":false,"items":_vm.talentPoolEntry.jobHistory,"disable-pagination":"","hide-default-footer":"","fixed-header":"","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"header.title",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.stage",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.candidate.step.name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.candidate.stage",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text, { postfix: '' }))+" ")]}},{key:"item.stage",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:("v-chip-light-bg " + (_vm.resolveJobStageColor(item.stage).color) + "--text font-weight-semibold text-capitalize"),attrs:{"label":"","small":"","color":_vm.resolveJobStageColor(item.stage).color}},[_vm._v(_vm._s(_vm.resolveJobStageColor(item.stage).text))])]}},{key:"item.title",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-semibold text-truncate cursor-pointer text-decoration-none ms-3",attrs:{"to":{ name: 'apps-ats-job-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.candidate.stage",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{class:("v-chip-light-bg " + (_vm.resolveCandidateStageColor(item.candidate.stage).color) + "--text font-weight-medium text-capitalize"),attrs:{"small":"","label":"","color":_vm.resolveCandidateStageColor(item.candidate.stage).color}},[_vm._v(" "+_vm._s(_vm.resolveCandidateStageColor(item.candidate.stage).text)+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{
                    name: 'apps-ats-candidate-view',
                    params: { jobId: item.id, candidateId: item.candidate.candidate.id },
                  }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('view'),expression:"'view'"}]})])],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }