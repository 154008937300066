<template>
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title v-t="'edit'" class="justify-center text-h5" />
      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="12">
              <v-combobox
                v-model="talentPoolEntryDataLocal.tags"
                :items="tagsChoices"
                :label="$t('tag')"
                :placeholder="$t('tag')"
                item-text="tag"
                item-value="id"
                multiple
                outlined
                dense
                hide-details
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-bind="attrs"
                    color="info"
                    outlined
                    :input-value="selected"
                    small
                    label
                    class="font-weight-semibold disable-min-height--chip mt-1"
                  >
                    <span class="pr-3">{{ item === Object(item) ? item.tag : item }}</span>
                    <v-icon x-small @click="parent.selectItem(item)"> $delete </v-icon>
                  </v-chip>
                </template>
              </v-combobox></v-col
            >
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-t="'discard'"
                outlined
                color="secondary"
                class="me-3"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              />
              <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import _ from 'lodash'
import employeeUtils from '@/utils/employeeUtils'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    talentPoolEntryData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      fetchTagChoices,

      tagsChoices,
    } = employeeUtils()

    const { updateTalentPoolEntryDetails } = useActions('talentPool', ['updateTalentPoolEntryDetails'])

    const valid = ref(true)
    const loading = ref(false)

    const bioEditForm = ref(null)

    const talentPoolEntryDataLocal = ref({})

    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      const { success } = await updateTalentPoolEntryDetails({
        id: props.talentPoolEntryData.id,
        payload: {
          tags: talentPoolEntryDataLocal.value.tags.map((tag) => (_.isPlainObject(tag) ? tag : { tag })),
        },
      })
      loading.value = false

      if (success) {
        emit('update:is-bio-dialog-open', false)
      }
    }

    watch(
      () => props.isBioDialogOpen,
      async () => {
        talentPoolEntryDataLocal.value = _.cloneDeep({
          tags: props.talentPoolEntryData.tags,
        })
        await fetchTagChoices()
      },
    )

    const areDataChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(talentPoolEntryDataLocal.value.tags, props.talentPoolEntryData.tags, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(props.talentPoolEntryData.tags, talentPoolEntryDataLocal.value.tags, _.isEqual))
      )
    })

    return {
      handleFormSubmit,

      areDataChanged,

      valid,
      loading,
      bioEditForm,
      talentPoolEntryDataLocal,
      tagsChoices,
    }
  },
}
</script>
