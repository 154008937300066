<template>
  <div class="user-tab-security">
    <v-card class="mb-7">
      <v-card-title v-t="'Consent'" />
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card outlined class="card--border-primary">
              <v-card-text>
                <div>{{ talentPoolEntry.consent.consent }}</div>
                <v-divider class="mt-1 mb-1"></v-divider>
                <div class="d-flex justify-end align-center">
                  {{ `${talentPoolEntry.consent.accepted ? $t('acceptedConsent') : $t('declinedConsent')}` }}
                  {{ formatDateToMonthShort(talentPoolEntry.consent.acceptedOn) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { useState } from 'vuex-composition-helpers'

import { formatDateToMonthShort } from '@core/utils/filter'

export default {
  setup() {
    const { talentPoolEntry } = useState('talentPool', ['talentPoolEntry'])

    return {
      talentPoolEntry,
      formatDateToMonthShort,
    }
  },
}
</script>
